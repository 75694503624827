import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { DefaultButton } from 'office-ui-fabric-react';
import { FormattedMessage } from 'react-intl';
import { TooltipHost, DirectionalHint, Popup } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useHistory } from 'react-router-dom';

import { ai } from '../ApplicationInsightsProvider/ApplicationInsightsService';
import { useEndpointActions } from '../../store/endpoint';
import { GetCopilotActions } from '../../store/stores/copilot';
import { IApplicationState } from '../../store';
import { useAuthenticatedUser } from '../../hooks/useAuthenticatedUser';
import { useAccessToken } from '../../hooks/useAccessToken';
import { Helper } from '../../utilities';
import { trackCopilotChatOpened } from '../ApplicationInsightsProvider/CopilotTelemetryService';
import FeatureFlagValues from '../../utilities/featureFlagValues';
import CopilotLogo from '../CopilotLogo/CopilotLogo';
import { COPILOT_BOT_CONST } from './CopilotBotConst';

import './CopilotBot.scss';

interface ICopilotBot {
  showCopilotBot?: boolean;
  changeCopilotVisibility: (val: any) => void;
  isUserLoggedIn?: boolean;
}

const currentTime = new Date().getTime() + Math.floor(Math.random() * 1000000);

/**
 * Component to verify the user has accepted the Eula before showing the children components
 *
 * @param props The properties
 */
const CopilotBot: React.FunctionComponent<ICopilotBot> = props => {
  const { showCopilotBot, isUserLoggedIn } = props;
  const accessToken = useAccessToken(isUserLoggedIn);
  const history = useHistory();
  const [profile] = useSelector((state: IApplicationState) => [state.UserStore.profile], shallowEqual);
  const [token] = useSelector((state: IApplicationState) => [state.CopilotStore.copilot.token], shallowEqual);
  const [emailProfile] = useSelector((state: IApplicationState) => [state.UserStore.emailprofile], shallowEqual);
  const tooltipId = useId('tooltip');
  const copilotHeader = useId('copilot-header');
  const ref = useRef<HTMLIFrameElement>(null);
  const [iframeLoad, setIframeLoad] = useState<boolean>(false);

  const [learnerId, setLearnerId] = useState(profile.id);
  const userId = 'dl_' + learnerId;

  const [getCopilotActions] = useEndpointActions([GetCopilotActions]);
  const account = useAuthenticatedUser();
  const copilotBotEnable = FeatureFlagValues().enableCopilot;

  const chatBotSrc = process.env.REACT_APP_COPILOT_URL ? process.env.REACT_APP_COPILOT_URL : '';
  const { lxpText, elaText } = COPILOT_BOT_CONST;

  const _loadIframe = useCallback(() => {
    setIframeLoad(true);
  }, []);

  useEffect(() => {
    if (account && account.oid) {
      //setOid(account.oid);
      setLearnerId(profile.id);
    }
  }, [account, profile.id]);

  useEffect(() => {
    if (userId.substring(0, 3) === 'dl_' && userId.length > 4 && token === '') {
      getCopilotActions({ userId });
    }
  }, [getCopilotActions, token, userId]);

  useEffect(() => {
    if (iframeLoad && ai.appInsights) {
      const iframe = ref.current;
      if (iframe !== null) {
        const iWindow = iframe.contentWindow;
        if (iWindow !== null) {
          const dataToPass = {
            sourceName: lxpText,
            userDetail: {
              accountDetail: account || '',
              emailProfile: emailProfile || ''
            },
            locale: Helper.getLocale(),
            telemetryData: {
              sessionId: ai?.appInsights?.context?.sessionManager?.automaticSession?.id,
              additionalProps: {
                learnerId: learnerId,
                userId: account?.oid
              }
            },
            isUserLoggedIn
          };
          iWindow.postMessage(dataToPass, chatBotSrc);
        }
      }
    }
  }, [iframeLoad, learnerId, ref, chatBotSrc, account, emailProfile, lxpText, isUserLoggedIn]);

  useEffect(() => {
    const handlePostMessage = (event: { data: { link: string; isAccessToken: boolean; sourceName: string } }) => {
      const { data } = event;
      if (data) {
        const { link, isAccessToken, sourceName } = data;
        if (sourceName === elaText) {
          if (link) {
            history.push('/' + event.data.link);
          }
          if (isAccessToken) {
            const iframe = ref.current;
            if (iframe !== null) {
              const iWindow = iframe.contentWindow;
              if (iWindow !== null) {
                const dataToPass = {
                  accessToken: accessToken,
                  sourceName: lxpText,
                  isUserLoggedIn
                };
                iWindow.postMessage(dataToPass, chatBotSrc);
              }
            }
          }
        }
      }
    };

    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, [accessToken, chatBotSrc, elaText, history, isUserLoggedIn, lxpText]);

  if ((!account || !account.oid) && !isUserLoggedIn) {
    return <div></div>;
  }

  const closeCopilot = () => {
    const { changeCopilotVisibility } = props;
    Helper.setClarityCustomTag('CopilotChatAction', 'ChatClosed');
    const milliseconds = Date.now() - parseInt(localStorage.getItem('copilotChatOpened') || '0');
    const minutes = Math.floor(milliseconds / 60000);
    if (ai.appInsights) {
      ai.appInsights.trackTrace({
        message: 'Page :Lxp Assist Chat Closed Durations',
        severityLevel: SeverityLevel.Information,
        properties: {
          learnerId: profile.id,
          EmailAddress: profile.emailAddress,
          duration: minutes
        }
      });
    }
    changeCopilotVisibility(false);
    localStorage.removeItem('copilotChatOpened');
  };

  if (showCopilotBot && ai.appInsights) {
    Helper.setClarityCustomTag('CopilotChatAction', 'ChatOpened');
    trackCopilotChatOpened();
    localStorage.setItem('copilotChatOpened', Date.now().toString());
  }

  return (
    <div className="chat-container">
      <div className={showCopilotBot ? 'chat-window' : 'chat-window-hidden'}>
        <Popup role="dialog" ariaLabelledBy={copilotHeader}>
          <div className={copilotBotEnable ? 'chat-box chat-box-copilot-2' : 'chat-box'}>
            <div className="chat-header">
              <div className="chat-header-title">
                <CopilotLogo />
                <span role="heading" aria-level={2} id={copilotHeader}>
                  <FormattedMessage defaultMessage="Enterprise Learner Assist" />
                </span>
              </div>
              <TooltipHost
                content="Close Enterprise Learner Assist"
                id={tooltipId}
                calloutProps={{ gapSpace: 0 }}
                styles={{ root: { display: 'inline-block' } }}
                directionalHint={DirectionalHint.leftCenter}
              >
                <DefaultButton className="chatHeaderClose" onClick={closeCopilot} text="X" aria-describedby={tooltipId} />
              </TooltipHost>
            </div>
            <div className={isUserLoggedIn ? 'webchat-container webchat-container-full-height' : 'webchat-container'}>
              <iframe
                className={isUserLoggedIn ? 'iframe-full-height' : ''}
                src={`${chatBotSrc}?t=${currentTime}`}
                title="Chat Bot"
                ref={ref}
                onLoad={_loadIframe}
              ></iframe>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default CopilotBot;
